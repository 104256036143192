<template>
    <div class="app-container">
        <div class="filter-container">
            <el-date-picker
                    class="filter-item"
                    v-model="pickerOpts.pickerData"
                    :picker-options="pickerOpts"
                    type="datetimerange"
                    @change="setDateRange"
                    :range-separator="$t('commons.rangeSeparator')"
                    :start-placeholder="$t('commons.startDate')"
                    :end-placeholder="$t('commons.endDate')">
            </el-date-picker>
            <el-select @change="handleFilter" v-model="listQuery.tagType" filterable clearable :placeholder="$t('tag.tagType')">
                <el-option
                        v-for="item in tagTypeList"
                        :key="item.itemCode"
                        :value="item.itemCode"
                        :label="item.itemName">
                </el-option>
            </el-select>
            <el-input :placeholder="$t('tag.tagName')" maxlength="80" v-model="listQuery.tagName" clearable
                      style="width: 200px;" class="filter-item" @keyup.enter.native="handleFilter"/>
            <el-button class="filter-item" type="primary" icon="el-icon-search" :loading="listLoading" @click="handleFilter">{{
                $t('commons.search') }}
            </el-button>
            <el-button type="primary" icon="el-icon-download" plain @click="exportTag">{{$t('commons.export')}}</el-button>
        </div>
        <div class="filter-container">
            <el-button type="primary" icon="el-icon-plus" size="mini" plain class="filter-item" @click="addTag">
                {{$t('commons.add')}}
            </el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="batchDelete" plain>{{$t('commons.delete')}}</el-button>
            <el-button type="primary" icon="el-icon-upload" size="mini" plain @click="downTagTemplate">{{$t('commons.importTemplate')}}</el-button>
            <el-button type="primary" icon="el-icon-upload2" size="mini" plain @click="uploadModel = true">{{$t('commons.import')}}</el-button>
        </div>
        <el-table
                v-loading="listLoading"
                :data="list"
                element-loading-text="Loading"
                border
                fit
                highlight-current-row
                stripe @selection-change="handleSelectionChange">
            <el-table-column
                    prop="tagId"
                    fixed type="selection" align="center" width="50">
            </el-table-column>
            <el-table-column :label="$t('tag.tagType')" align="center">
                <template slot-scope="scope">
                    <template v-if="scope.row.edit">
                        <el-select v-model="scope.row.tagType" filterable clearable>
                            <el-option
                                    v-for="item in tagTypeList"
                                    :key="item.itemCode"
                                    :value="item.itemCode"
                                    :label="item.itemName">
                            </el-option>
                        </el-select>
                    </template>
                    <span v-else>{{ scope.row.tagType }}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('tag.tagName')" align="center">
                <template slot-scope="scope">
                    <template v-if="scope.row.edit">
                        <el-input v-model="scope.row.tagName" maxlength="80" :show-word-limit="true" size="small" clearable :placeholder="$t('commons.pleaseInput')"/>
                    </template>
                    <span v-else>{{ scope.row.tagName }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="userName" :label="$t('tag.userName')" align="center">
            </el-table-column>
            <el-table-column :label="$t('commons.lastUpdateDate')" align="center">
                <template slot-scope="scope">
                    <i class="el-icon-time"/>
                    <span v-if="scope.row.lastUpdateDate != null"> {{ scope.row.lastUpdateDate | dateFormat('YYYY-MM-DD HH:mm:ss') }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="operation" :label="$t('commons.actions')" align="center">
                <template slot-scope="scope">
                    <template v-if="scope.row.edit">
                        <div class="ruge-buttons">
                            <a class="ruge-button" @click="updateTag(scope.row)" :title="$t('commons.save')"><i class="el-icon-circle-check"></i></a>
                            <a class="ruge-button"  @click="cancelTagEdit(scope.row)" :title="$t('commons.cancel')"><i class="el-icon-circle-close"></i></a>
                        </div>
                    </template>
                    <template v-else>
                        <div class="ruge-buttons">
                            <a class="ruge-button" @click="editTag(scope.row)" :title="$t('commons.edit')"><i class="el-icon-edit-outline"></i></a>
                            <a class="ruge-button" @click="deleteTag(scope.row)" :title="$t('commons.delete')"><i class="el-icon-delete delete-btn"></i></a>
                        </div>
                    </template>
                </template>
            </el-table-column>
        </el-table>
        <pagination v-show="total>0" :total="total" :page.sync="listQuery.current" :limit.sync="listQuery.rowCount"
                    @pagination="getTagPage"/>

        <!-- 添加通知对话框 -->
        <el-dialog :visible.sync="addDialog"
                   :before-close="cancelTagSave" width="30%" :close-on-click-modal="false">
            <template slot="title">
                <title-icon/>{{ $t('tag.create') }}
            </template>
            <el-form label-position="top" :model="form" ref="tagForm" :rules="formRules">
                <el-form-item :label="$t('tag.tagType')" prop="tagType">
                    <el-select v-model="form.tagType" filterable clearable style="width: 100%;">
                        <el-option
                                v-for="item in tagTypeList"
                                :key="item.itemCode"
                                :value="item.itemCode"
                                :label="item.itemName">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('tag.tagName')" prop="tagName">
                    <el-input v-model="form.tagName" maxlength="80" :show-word-limit="true" autocomplete="off" clearable :placeholder="$t('commons.pleaseInput')"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button @click="cancelTagSave">{{$t('commons.cancel') }}</el-button>
                <el-button type="primary" @click="handleTagSave"
                           v-loading.fullscreen.lock="createLoading">{{$t('commons.save') }}
                </el-button>
            </div>
        </el-dialog>

        <!--导入文件-->
        <el-dialog :visible.sync="uploadModel" width="40%">
            <template slot="title">
                <title-icon/>{{ $t('commons.uploadFile') }}
            </template>
            <div slot="footer">
                <upload :ulType="uploadType" :excelType="excelType" :rootPath="rootPath" @success="uploadSuccess" @fail="uploadFail"></upload>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {
        getTagList,
        getTagTypeList,
        deleteTag,
        updateTag,
        tagIsExist,
        createTag,
        downTagTemplate,
        exportTag,
        batchDeleteTag
    } from '@/api/ruge/tag/tag'
    import Pagination from '@/components/Pagination'
    import Upload from '@/components/Upload'
    import { envInfo } from '@/constants/envInfo'
    import store from '@/store/index'

    export default {
        name: 'TagManager',
        components: {Pagination,Upload},
        data() {
           return{
               addDialog: false,
               createLoading: false,
               tagTypeList:[],
               list: null,
               total: 0,
               listLoading: true,
               uploadModel: false,
               uploadType: "ExcelImport",
               excelType: "ruge.tag",
               rootPath: envInfo.bgApp.tagPath,
               multipleSelection:[],
               listQuery: {
                   current: 1,
                   rowCount: 10,
                   tagType: null,
                   tagName: null,
                   startTime: null,
                   endTime: null
               },
               // 日期范围查询组件
               pickerOpts: {
                   // 默认日期
                   pickerData: null,
                   // 快捷选项
                   shortcuts: null,
               },
               form:{
                   tagType : null,
                   tagName : null,
                   oldTagType : null,
                   oldTagName : null
               },
               // 表单校验规则
               formRules: {
                   tagType: [{
                       required: true,
                       trigger: ['blur', 'change'],
                       message: this.$t('validate.required')
                   }],
                   tagName: [{
                       required: true,
                       trigger: ['blur', 'change'],
                       message: this.$t('validate.required')
                   }, {
                       validator : (rule, value, callback) =>{
                           tagIsExist({"tagType":this.form.tagType,"tagName":value}).then(res =>{
                               if(res == true){
                                   console.log(111)
                                   callback(new Error(this.$t('tag.tagIsExist')));
                               }
                           })
                           callback();
                       },
                       trigger: ['blur', 'change']
                   }],
               },
           }
        },
        created() {
            this.getTagPage();
            this.getTagTypeList();
        },
        methods: {
            getTagTypeList(){
                getTagTypeList({classifyCode: 'tag_type'}).then((row)=> {
                    this.tagTypeList = row;
                })
            },
            getTagPage() {
                this.listLoading = true
                getTagList(this.listQuery).then(response => {
                    response.rows.map(v =>{
                        v.edit = false;
                    })
                    this.list = response.rows;
                    this.total = response.total;
                    this.listLoading = false;
                })
            },
            handleFilter() {
                this.listQuery.current = 1
                this.getTagPage();
            },
            editTag(row) {
                this.setTag(row);
                row.edit = true;
            },
            cancelTagEdit(row) {
                this.resetTag(row);
                row.edit = false;
            },
            updateTag(row) {
                this.listLoading = true;
                updateTag(row).then(() => {
                    this.$message({type: 'success', message: this.$t('message.operationSuccess')});
                    row.edit = false;
                    this.listLoading = false;
                    this.setTag(row);
                }).catch(() => {
                    this.listLoading = false;
                })
            },
            deleteTag(row){
                this.$confirm(this.$t('message.deleteConfirm'),this.$t('commons.warning'),{
                    confirmButtonText:this.$t('commons.confirm'),
                    cancalButtonText:this.$t('commons.cancel'),
                    type:'warning'
                }).then(()=>{
                    deleteTag({"tagId":row.tagId}).then(()=>{
                        //成功提示
                        this.$message({
                            message:this.$t('message.deleteSuccess'),
                            type:'success'
                        })
                        //删除成功后重新渲染table
                        this.handleFilter();
                    }).catch(error =>{
                        console.log(`删除失败，原因 => ${error}`)
                    })
                }).catch(error =>{
                    console.log(`未删除，原因 => ${error}`)
                })
            },
            addTag(){
                this.addDialog = true;
                this.form.tagType = null;
                this.form.tagName = null;
            },
            resetTag(row) {
                row.tagType = row.ordTagType;
                row.tagName = row.ordTagName;
            },
            cancelTagSave() {
                this.addDialog = false;
                // 还原原有值，用于取消编辑
                this.resetTag(this.form);
            },
            setTag(row) {
                row.ordTagType = row.tagType;
                row.ordTagName = row.tagName;
            },
            //保存通知
            handleTagSave() {
                this.$refs.tagForm.validate((valid) => {
                    if (valid) {
                        createTag(this.form).then(() =>{
                            // 成功后重置form,并关闭弹框和遮罩层
                            this.addDialog = false;
                            this.createLoading = false;
                            // 成功提示
                            this.$message({
                                message: this.$t('message.saveSuccess'),
                                type: 'success'
                            });
                            // 刷新列表
                            this.handleFilter();
                        })
                    }
                });
            },
            uploadSuccess(){
                this.uploadModel = false
                this.$message({type: 'success', message: this.$t('message.uploadSuccess')});
            },
            uploadFail() {
                this.$message({type: 'error', message: this.$t('message.uploadFial')});
            },
            downTagTemplate(){
                downTagTemplate();
            },
            exportTag(){
                exportTag(this.listQuery).then((msg)=>{
                    this.$message({type: 'success', message: this.$t('message.operationSuccess')});
                    let exportObj = {
                        taskId : msg,
                        taskName : "Tag",
                        taskStatus : 0,
                        rootPath : 'tagPath'
                    };
                    //将导出任务丢入导出任务列表中
                    store.dispatch('PushExportNotice',exportObj);
                })
            },
            handleSelectionChange(data){
                let tagIds = [];
                data.forEach(function (obj) {
                    tagIds.push(obj.tagId);
                });
                this.multipleSelection = tagIds;
            },
            batchDelete(){
                if(this.multipleSelection.length < 1){
                    this.$message({
                        type: 'info',
                        message: this.$t('commons.multipleSelectionOne')
                    });
                }else{
                    this.$confirm(this.$t('message.deleteConfirm'), this.$t('commons.warning'), {
                        confirmButtonText: this.$t('commons.confirm'),
                        cancelButtonText: this.$t('commons.cancel'),
                        type: 'warning'
                    }).then(() => {
                        batchDeleteTag({"tagIds":this.multipleSelection.join(",")}).then(()=>{
                            // 成功提示
                            this.$message({
                                message: this.$t('message.deleteSuccess'),
                                type: 'success'
                            });
                            this.handleFilter();
                        });
                    }).catch(error => {
                        console.log(`未删除，原因 => ${error}`)
                    });
                }
            },
            // 设置日期范围查询条件
            setDateRange(dateRange) {
                if (dateRange) {
                    this.listQuery.startTime = dateRange[0].getTime();
                    this.listQuery.endTime = dateRange[1].getTime();
                } else {// 清空
                    this.listQuery.startTime = null;
                    this.listQuery.endTime = null;
                }
            },
        }
    }
</script>
